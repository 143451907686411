import { IGetBuyItAgainProductsRequest } from "@faire/web-api/faire/bia/client/IGetBuyItAgainProductsRequest";

const PAST_ORDERS_CATEGORY_FILTER_ID = "Past Orders";
export function createGetBuyItAgainProductsRequest(
  brandToken: string
): IGetBuyItAgainProductsRequest {
  return IGetBuyItAgainProductsRequest.build({
    filter_keys: [`category:${PAST_ORDERS_CATEGORY_FILTER_ID}`],
    brand_token: brandToken,
    page_number: 1,
    page_size: Math.max(CAROUSEL_PAGE_SIZE, TABLET_PAGE_SIZE),
    from_buy_it_again_carousel: true,
  });
}

export const CAROUSEL_PAGE_SIZE = 5;
export const TABLET_PAGE_SIZE = 3;
