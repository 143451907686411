export const SIDE_MENU_WIDTH = "232px";
export const MENU_PRODUCTS_SPACE_SMALL = "3x";
export const TOP_OF_PRODUCT_SPACE: number = 16;

export const SHOP_SECTION_ID = "shop-section";
export const REVIEWS_SECTION_ID = "reviews-section";

export const COMMISSION_NAM_UPDATE_BLOG_POST_LINK =
  "/blog/selling/north-america-pricing-update/";

export const BRAND_CART_WIDTH_WIDE = {
  large: "320px",
  xlarge: "360px",
};
