import { IFDMarketplaceDiscountData } from "@faire/web-api/indigofair/data/IFDMarketplaceDiscountData";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";

export const getFaireDirectMarketplaceDiscountData = ():
  | IFDMarketplaceDiscountData
  | undefined => {
  return getGlobalProperty(
    "fdMarketplaceDiscountData",
    IFDMarketplaceDiscountData.build({})
  );
};
