"use client";

import {
  useQuery as useMilestonesQuery,
  getQueryKey as getMilestonesQueryKey,
} from "@faire/web-api/api/user/milestones/userToken/list/get-hooks";
import { useMutation as useMilestoneMutation } from "@faire/web-api/api/user/milestones/userToken/post-hooks";
import { IGetUserMilestonesResponse } from "@faire/web-api/indigofair/data/IGetUserMilestonesResponse";
import { IUserMilestone } from "@faire/web-api/indigofair/data/IUserMilestone";
import { useQueryClient } from "@tanstack/react-query";
import differenceInDays from "date-fns/differenceInDays";
import { useCallback, useMemo } from "react";

import { getUser } from "@faire/retailer/serialized-data/getUser";
import { getUserMilestones } from "@faire/retailer/serialized-data/getUserMilestones";

const getInitialMilestoneData = () => {
  const milestones = getUserMilestones();
  if (!milestones) {
    return undefined;
  }
  return IGetUserMilestonesResponse.build({
    milestones: milestones.reduce(
      (acc, milestone) => ({
        ...acc,
        [milestone.retailer_milestone!]: milestone,
      }),
      {} as Record<string, IUserMilestone>
    ),
  });
};

const useMilestonesData = ():
  | Partial<Record<string, IUserMilestone>>
  | undefined => {
  const userToken = getUser()?.token;
  const { data } = useMilestonesQuery(userToken ?? "", {
    initialData: getInitialMilestoneData(),
    enabled: !!userToken,
    staleTime: Infinity,
  });
  return data?.milestones;
};

export function useRecordMilestone(): (
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone
) => Promise<unknown> {
  const userToken = getUser()?.token;
  const { mutateAsync } = useMilestoneMutation();
  const queryClient = useQueryClient();
  return useCallback(
    async (milestoneType: keyof typeof IUserMilestone.RetailerMilestone) => {
      if (!userToken) {
        return Promise.resolve();
      }
      return mutateAsync([
        userToken,
        IUserMilestone.build({ retailer_milestone: milestoneType }),
      ]).then((updatedMilestone) => {
        queryClient.setQueryData<IGetUserMilestonesResponse>(
          getMilestonesQueryKey(userToken),
          (prev) => {
            return IGetUserMilestonesResponse.build({
              milestones: {
                ...prev?.milestones,
                [milestoneType]: updatedMilestone,
              },
            });
          }
        );
      });
    },
    [mutateAsync, queryClient, userToken]
  );
}

export const useMilestone = (
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone
): {
  hasOccurred: boolean;
  count: number;
  setHasOccurred: () => Promise<unknown>;
  incrementCount: () => Promise<unknown>;
} => {
  const milestones = useMilestonesData();
  const count = milestones?.[milestoneType]?.count || 0;
  const hasOccurred = count > 0;

  const record = useRecordMilestone();

  const setHasOccurred = useCallback(async () => {
    if (!hasOccurred) {
      record(milestoneType);
    }
    return Promise.resolve();
  }, [hasOccurred, record, milestoneType]);

  const incrementCount = useCallback(
    () => record(milestoneType),
    [record, milestoneType]
  );

  return useMemo(
    () => ({ hasOccurred, count, setHasOccurred, incrementCount }),
    [count, hasOccurred, incrementCount, setHasOccurred]
  );
};

export function useRecordMilestoneOnce(): (
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone
) => Promise<unknown> {
  const milestones = useMilestonesData();
  const record = useRecordMilestone();

  return useCallback(
    (milestoneType: keyof typeof IUserMilestone.RetailerMilestone) => {
      if (!milestones?.[milestoneType]?.count) {
        return record(milestoneType);
      }
      return Promise.resolve();
    },
    [milestones, record]
  );
}

export function useRecordMilestoneByDay(): (
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone
) => Promise<unknown> {
  const milestones = useMilestonesData();
  const record = useRecordMilestone();

  return useCallback(
    (milestoneType: keyof typeof IUserMilestone.RetailerMilestone) => {
      const milestone = milestones?.[milestoneType];
      if (!milestone?.count) {
        return record(milestoneType);
      }
      const updatedAt = milestone?.updated_at ?? 0;
      const now = Date.now();
      if (differenceInDays(now, updatedAt) >= 1) {
        return record(milestoneType);
      }
      return Promise.resolve();
    },
    [milestones, record]
  );
}

export function useIsMilestoneOverWeekOld(
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone
): boolean {
  return useIsMilestoneOverDaysOld(milestoneType, 7);
}

export function useIsMilestoneOverDaysOld(
  milestoneType: keyof typeof IUserMilestone.RetailerMilestone,
  days: number
): boolean {
  const milestone = useMilestonesData()?.[milestoneType];
  if (!milestone?.count) {
    return false;
  }
  const updatedAt = milestone?.updated_at ?? 0;
  const now = Date.now();
  if (differenceInDays(now, updatedAt) >= days) {
    return true;
  }
  return false;
}
