import { IBrandDiscount } from "@faire/web-api/indigofair/data/IBrandDiscount";
import find from "lodash/find";

import { getFaireDirectBrandToken } from "@faire/retailer/serialized-data/getFaireDirectBrandToken";

const FAIRE_DIRECT_BRAND_DISCOUNT_TYPE = "ELEVATE_SIGNUP";

export const parseFaireDirectFirstOrderIncentiveFromBrandDiscounts = (
  brandDiscounts: Partial<Record<string, IBrandDiscount>>
): IBrandDiscount | undefined => {
  const brandToken = getFaireDirectBrandToken();

  if (brandToken) {
    const discount = brandDiscounts[brandToken];

    if (discount && discount.type === FAIRE_DIRECT_BRAND_DISCOUNT_TYPE) {
      return discount;
    }
  }

  return find(
    brandDiscounts,
    (discount) => discount?.type === FAIRE_DIRECT_BRAND_DISCOUNT_TYPE
  );
};
