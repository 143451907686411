import { IRetailerPageData } from "@faire/web-api/faire/home_resource/IRetailerPageData";
import { readPrefetchedDataFactory } from "@faire/web/common/server-side-rendering/ssrPrefetch";

/**
 * Read bundled web-retailer data from the globals.
 *
 * Bundled data is any data that we fetched on the backend along with the data
 * needed to render the HTML document. This was historically called "backend prefetch",
 * but we have attempted to disambiguate prefetching (calling endpoints early from the client)
 * from bundling (loading extra data during the backend data fetch).
 */
export const readBundledData =
  readPrefetchedDataFactory<IRetailerPageData.IPrefetchedData>();
