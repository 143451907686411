import { Storage } from "@faire/web/common/Storage";
import { isPrerelease } from "@faire/web/common/user-agent/isPrerelease";

// Used in Cypress code which is outside the module
export const IS_E2E_TEST_FLAG = "IS_E2E_TEST";

// This should return true if we are running in a Cypress End-to-End test context
export const isE2E = (): boolean => {
  return Storage.local.getItem(IS_E2E_TEST_FLAG) === "true";
};

export const isNonSmokeE2E = (): boolean => {
  return isE2E() && !isPrerelease();
};
