import { responsiveMediaQueries } from "@faire/web/slate/Theme/responsiveMediaQueries";
import styled from "styled-components";

import { BRAND_CART_WIDTH_WIDE } from "./consts";

export const INNER_MAKER_CONTAINER_MAX_WIDTH = 1600;

export const getInnerMakerContainerMaxWidthXLargeAndAbove = () => {
  return `${INNER_MAKER_CONTAINER_MAX_WIDTH}px - ${BRAND_CART_WIDTH_WIDE.xlarge}`;
};

export const InnerMakerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${responsiveMediaQueries.xLargeAndAbove} {
    max-width: calc(${getInnerMakerContainerMaxWidthXLargeAndAbove()});
  }
`;
