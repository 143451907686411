const CATEGORY_NAVIGATION_PATH_SEPARATOR = "|";

export function buildCategoryNavigationPath(urlIds: string[]) {
  return urlIds
    .map((urlId) => decodeURI(urlId))
    .join(CATEGORY_NAVIGATION_PATH_SEPARATOR);
}

export function getUrlIdsFromNavigationPath(navigationPath: string) {
  return navigationPath.split(CATEGORY_NAVIGATION_PATH_SEPARATOR);
}
