import { IFaireMoney } from "@faire/web-api/indigofair/data/IFaireMoney";
import { FAIRE_DIRECT_RETAILER_AWARD_AMOUNT } from "@faire/web-api/indigofair/settings/FAIRE_DIRECT_RETAILER_AWARD_AMOUNT";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

const DEFAULT_ELEVATE_RETAILER_AWARD_AMOUNT: IFaireMoney = IFaireMoney.build({
  amount_cents: 100_00,
  currency: "USD",
});

export const getSettingFaireDirectRetailerAwardAmount = (): IFaireMoney =>
  getSettingValueWithoutAssignment(
    FAIRE_DIRECT_RETAILER_AWARD_AMOUNT,
    DEFAULT_ELEVATE_RETAILER_AWARD_AMOUNT
  );
