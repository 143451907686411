import { StoreType } from "@faire/retailer-shared/lib/StoreType";
import { IStyleQuiz } from "@faire/web-api/indigofair/data/IStyleQuiz";

/**
 * Fields that IStyleQuiz.StyleQuizStoreType may return but do not have corresponding
 * StoreType values -- and are therefore deemed invalid.
 */
const invalidStyleQuizAnswers: (keyof typeof IStyleQuiz.StyleQuizStoreType)[] =
  ["STATIONERY", "KIDS_AND_BABY", "STYLE_QUIZ_STORE_TYPE_UNKNOWN"];

export const mapStyleQuizAnswerToStoreType = (
  styleQuizAnswer: keyof typeof IStyleQuiz.StyleQuizStoreType | undefined
): StoreType | undefined => {
  if (!styleQuizAnswer) {
    return;
  }

  const styleQuizAnswerToStoreType = invalidStyleQuizAnswers.includes(
    styleQuizAnswer
  )
    ? "OTHER"
    : (styleQuizAnswer as keyof typeof StoreType);

  return StoreType[styleQuizAnswerToStoreType];
};
