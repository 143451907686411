import { useBrandDiscounts } from "@faire/retailer/stores/domain/useBrandDiscounts";

import { getFaireDirectFirstOrderIncentiveAmountFields } from "./__internal__/getFaireDirectFirstOrderIncentiveAmountFields";
import { useFaireDirectFirstOrderIncentiveExpiresAtFields } from "./__internal__/useFaireDirectFirstOrderIncentiveExpiresAtFields";
import { useFaireDirectFirstOrderIncentiveHasIncentiveFields } from "./__internal__/useFaireDirectFirstOrderIncentiveHasIncentiveFields";
import { parseFaireDirectFirstOrderIncentiveFromBrandDiscounts } from "./shared/parseFaireDirectFirstOrderIncentiveFromBrandDiscounts";

export const useFaireDirectFirstOrderIncentive = () => {
  const { brandDiscounts, refetch } = useBrandDiscounts();
  const faireDirectFirstOrderIncentive =
    parseFaireDirectFirstOrderIncentiveFromBrandDiscounts(brandDiscounts);

  const { brand_token: brandToken, brand_name: brandName } =
    faireDirectFirstOrderIncentive ?? {};

  const hasIncentiveFields =
    useFaireDirectFirstOrderIncentiveHasIncentiveFields(
      faireDirectFirstOrderIncentive
    );

  const expiresAtFields = useFaireDirectFirstOrderIncentiveExpiresAtFields(
    faireDirectFirstOrderIncentive
  );

  const incentiveAmountFields = getFaireDirectFirstOrderIncentiveAmountFields(
    faireDirectFirstOrderIncentive
  );

  const refetchFaireDirectFirstOrderIncentive = refetch;

  return {
    brandToken,
    brandName,
    ...hasIncentiveFields,
    ...expiresAtFields,
    ...incentiveAmountFields,
    refetchFaireDirectFirstOrderIncentive,
  };
};
