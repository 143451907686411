import { SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO } from "@faire/web-api/indigofair/settings/SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingShouldDisplayRetrievalAndRankingDebugInfo =
  (): boolean => {
    return getSettingValueWithoutAssignment(
      SHOULD_DISPLAY_RETRIEVAL_AND_RANKING_DEBUG_INFO,
      false
    );
  };
