import { IBrandDiscount } from "@faire/web-api/indigofair/data/IBrandDiscount";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "@faire/web/common/consts/THREE_LETTER_PRIMARY_COUNTRY_CODES";
import { useCallback } from "react";

import { useRetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

import { hasFaireDirectFirstOrderIncentive } from "../shared/hasFaireDirectFirstOrderIncentive";

export const useFaireDirectFirstOrderIncentiveHasIncentiveFields = (
  faireDirectFirstOrderIncentive: IBrandDiscount | undefined
) => {
  const hasIncentive = hasFaireDirectFirstOrderIncentive(
    faireDirectFirstOrderIncentive
  );

  const hasIncentiveAndBrandTokenMatches = useCallback(
    (brandTokenToMatch: string) => {
      return (
        hasIncentive &&
        faireDirectFirstOrderIncentive?.brand_token === brandTokenToMatch
      );
    },
    [faireDirectFirstOrderIncentive?.brand_token, hasIncentive]
  );

  const { isInternational } = useRetailerStore(["isInternational"]);
  const hasIncentiveAndBrandIsBasedInCanadaOrUSA =
    getHasIncentiveAndBrandIsBasedInCanadaOrUSA({
      brandBasedIn: faireDirectFirstOrderIncentive?.brand_based_in,
      hasIncentive,
      isInternational,
    });

  return {
    hasIncentive,
    hasIncentiveAndBrandTokenMatches,
    hasIncentiveAndBrandIsBasedInCanadaOrUSA,
  };
};

const getHasIncentiveAndBrandIsBasedInCanadaOrUSA = ({
  brandBasedIn,
  hasIncentive,
  isInternational,
}: {
  brandBasedIn: string | undefined;
  hasIncentive: boolean;
  isInternational: boolean;
}) => {
  const brandIsBasedInCanadaOrUSA =
    brandBasedIn === THREE_LETTER_PRIMARY_COUNTRY_CODES.CAN ||
    brandBasedIn === THREE_LETTER_PRIMARY_COUNTRY_CODES.USA;

  return hasIncentive && brandIsBasedInCanadaOrUSA && !isInternational;
};
