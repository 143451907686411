export enum StoreType {
  GIFT = "Gift Store",
  CLOTHING = "Clothing Boutique",
  HOME_DECOR = "Home Decor Store",
  SPA_OR_SALON = "Spa / Salon / Beauty Store",
  GENERAL = "General Store / Mercantile",
  FLORIST_OR_GARDEN = "Florist / Garden Store",
  KIDS_OR_TOY = "Kids / Toy Store",
  CAFE_OR_RESTAURANT = "Cafe / Restaurant",
  FITNESS_OR_YOGA = "Fitness / Yoga Studio",
  FURNITURE = "Furniture Store",
  PET = "Pet Store",
  BOOK = "Bookstore",
  GROCERY_OR_LIQUOR = "Grocery / Liquor Store",
  OTHER = "Other",
}
