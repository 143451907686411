import { trackPricingOpeningOrderIncentivesViewOfferBannerView } from "@faire/web-api/events/pricing/view/openingOrderIncentivesViewOfferBanner";
import { IFaireMoney } from "@faire/web-api/indigofair/data/IFaireMoney";
import { intlDate } from "@faire/web/common/localization/date-fns/intlDate";
import { Storage } from "@faire/web/common/Storage";
import differenceInHours from "date-fns/differenceInHours";
import once from "lodash/once";

import { getOpeningOrderIncentivesInfo } from "@faire/retailer/serialized-data/getOpeningOrderIncentivesInfo";
import { useCallback, useMemo } from "react";
import { useStore } from "./OpeningOrderIncentivesContextStore";

export interface IOpeningOrderIncentivesProps {
  endDate: string;
  endDateWithTimezone: string;
  discountPercentage: number;
  discountCapAmount?: IFaireMoney;
}

const isDrivenFromEmailStorageKey = "OpeningOrderIncentivesIsDrivenFromEmail";

export const useOpeningOrderIncentives = () => {
  const [{ currentTime }, _] = useStore(["currentTime"]);

  const {
    discount_bps,
    discount_bps_cap_amount,
    expires_at,
    is_retailer_eligible,
  } = getOpeningOrderIncentivesInfo();

  const showOpeningOrderIncentives =
    is_retailer_eligible && expires_at && currentTime < expires_at;

  const numberOfHoursRemaining =
    expires_at && differenceInHours(expires_at, currentTime);

  const trackOOIBannerViewEvent = useCallback(
    () => once(trackPricingOpeningOrderIncentivesViewOfferBannerView),
    []
  );

  const openingOrderIncentivesProps = useMemo(() => {
    if (!expires_at || !discount_bps_cap_amount || !discount_bps) {
      return undefined;
    }

    return {
      endDate: intlDate(expires_at, "MMMM d"),
      endDateWithTimezone: intlDate(expires_at, "PPpx"),
      discountPercentage: discount_bps / 100,
      discountCapAmount: discount_bps_cap_amount,
    };
  }, [discount_bps, discount_bps_cap_amount, expires_at]);

  const isDrivenFromMarketingEmail = !!Storage.session.getItem(
    isDrivenFromEmailStorageKey
  );

  return {
    isDrivenFromMarketingEmail,
    numberOfHoursRemaining,
    openingOrderIncentivesProps,
    showOpeningOrderIncentives,
    trackOOIBannerViewEvent,
  };
};
